@font-face {
    font-family: 'Mollio Glastone';
    src: url('../src/Fonts/FontsFree-Net-Mollie-Glaston.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  .mollio{
    font-family: 'Mollio Glastone', sans-serif;
  }
  .outfit{
    font-family: "Outfit", sans-serif;
  }
  .jost{
    font-family: "Jost", sans-serif;
  }
  .navbar-logo{
    font-size: 40px !important;
    font-weight: 400 !important;
    color: white !important;
    font-family: 'Mollio Glastone', sans-serif !important;
  
  }
  .navbar-custom{
    background-color: rgba(79, 166, 140, 1);
     
  }
  .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.65) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    margin-right: 20px !important;
  }
  
  .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 1) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: "Jost", sans-serif !important;
   
  }
  
  @media (max-width: 768px) {
    .navbar-nav .nav-link {
      margin-right: 10px;
    }
    .Monovogue{
        font-size: 50px !important;
    }
  }
  .jost{
    font-family: "Jost", sans-serif;
  }
  /* =========== Swiper ==============  */
  
  .mySwiper {
    height: 80vh; 
  }
 
  
  .mySwiper .swiper-pagination-bullet {
     background:  rgba(79, 166, 140, 1);
  }
  .slide-1{
        background-image: url('./assets/header-bg1.svg');
        height:80vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100% 100%;
    
  }
  .slide-2{
    background-image: url('./assets/Header-bg2.svg');
    height:80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;
}
.slide-3{
    background-image: url('./assets/Header-bg3.svg');
    height:80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;
}
.slide-4{
    background-image: url('./assets/Header-bg4.svg');
    height:80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;
}
.slide-5{
    background-image: url('./assets/scroll-img5.svg');
    height:80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;

}
.slide-6{
    background-image: url('./assets/scroll-img6.svg');
    height:80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;

}
.slide-7{
    background-image: url('./assets/scroll-img7.svg');
    height:80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;

}
.slide-8{
    background-image: url('./assets/scroll-img8.svg');
    height:80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;

}
.slide-9{
    background-image: url('./assets/scroll-img9.svg');
    height:80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;

}
.slideOne{
    text-align: center;
    /* border: 1px solid hsla(162, 36%, 48%, 1); */
    box-shadow: 0px 0px 5px hsla(162, 36%, 48%, 1);
    background-image: linear-gradient(hsla(162, 58%, 26%, 1));
    border-radius: 29px;
    margin-left: 10%;
    margin-right: 10%;
    backdrop-filter: blur(25px);
    padding: 5px;
}
.slideTwo{
    box-shadow: 0px 0px 5px hsla(0, 0%, 0%, 0.3);
    background-image: linear-gradient(hsla(0, 0%, 0%, 0.3));
    border-radius: 17px;
    margin-left: 54%;
    margin-right: 0%;
    backdrop-filter: blur(30px);  
   
}
/* =========== Swiper End ==================   */
.fs-55{
    font-size: 55px;
}
.fs-19{
    font-size: 19px;
}
.fs-45{
    font-size: 45px;
}
.fs-25{
    font-size: 25px;
}
.fs-11{
  font-size: 11px;
}
.fs-18{
    font-size: 18px;
}
.fs-17{
    font-size: 17px;
}
.fs-20{
    font-size: 20px;
}
.fs-40{
    font-size: 40px;
}
.fs-22{
    font-size: 22px;
}
.fs-27{
    font-size: 27px;
}
.fs-16{
  font-size: 16px;
}
.fs-13{
  font-size: 13px;
}
.fs-21{
  font-size: 21px;
}
.fs-24{
  font-size: 24px;
}
.fs-28{
    font-size: 28px;
}
.fs-16{
    font-size: 16px;
}
.fs-30{
    font-size: 30px;
}
.fs-40{
    font-size: 40px;
}
.fs-100{
    font-size: 100px;
}
.fs-15{
  font-size: 15px;
}
.fs-90{
    font-size: 90px;
}
.fs-50{
    font-size: 50px;
}
.fw-400{
    font-weight: 400;
}
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}
.fw-800{
    font-weight: 800;
}
.fw-900{
    font-weight: 900;
}
.f-col{
    color: hsla(162, 36%, 48%, 1);
}
.pop{
    font-family: "Poppins", sans-serif;
}
.btn-1{
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: "Jost", sans-serif !important;
    background-color:hsla(162, 36%, 48%, 1) !important ;
    border-radius: 86px !important;
   color: white !important;

  
}
.home-con-1{
    border: 1px solid hsla(160, 33%, 95%, 1);
    border-radius: 29px;
}
.image-container{
  position: relative;

}
  .bottom-text {
    position: absolute;
    bottom: 10px; 
    left: 43%;
    transform: translateX(-50%);
    color: white; 
   
    font-family: "Jost", sans-serif !important;
    font-size: 20px; 
    font-weight: 500;
   
  }
  .bottom-text1 {
    position: absolute;
    bottom: 30px; 
    left: 45%;
    transform: translateX(-50%);
    color: black; 
    font-family: "Jost", sans-serif !important;
    font-size: 20px; 
    font-weight: 500;
  
  
  }
 
  .home-con-2{
    border: 1px solid hsla(160, 33%, 95%, 1);
    border-radius: 29px;
    
  }
   .homebg-1{
    background-image: url('./assets/home-bg-2.svg');
     height:100vh; 
     width:100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%; 
    position: relative;
  } 
  .homebg-2{ 
    background-image: url('./assets/home-bg-1.svg');
     height:100vh; 
     width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%; 
    position: relative;
  }
  .homebg-container1{
     width: 716px;
     height: 349px; 
    border-radius: 25px;
    box-shadow: 0px 0px 5px hsla(0, 0%, 0%, 0.25) ;
    border: 0.1px solid hsla(19, 32%, 85%, 1);
    backdrop-filter: blur(25px);
    padding: 25px;
    background-image: linear-gradient(#000000);
    position: absolute;
    top: 38%;
    left: 5%;
    right: 0%;
    bottom: 0%;
  }
  .homebg-container2{
    width: 716px;
    height: 349px; 
   border-radius: 25px;
   box-shadow: 0px 0px 5px hsla(0, 0%, 0%, 0.25) ;
   backdrop-filter: blur(25px);
   padding: 25px;
   background-image: linear-gradient(#000000);
   border: 0.1px solid hsla(19, 32%, 85%, 1);
   position: absolute;
   top: 28%;
   left: 41%;
   right: 0%;
   bottom: 0%;
 }
 /* ==================Video Section =================== */
 .video-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .video-container {
    position: relative;
    width: 500px; 
    height: 300px; 
  }
  
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px; 
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    background:white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  

  .homebgstyle1{
    background-image: url('./assets/homedecorbg1.svg');
    height:100vh; 

   background-repeat: no-repeat;
   background-size: cover;
   background-position: 100% 100%; 
   position: relative;
  }
  .dinnerbgstyle1{
    background-image: url('./assets/dinnersets-bg1.svg');
    height:100vh; 
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 100% 100%; 
   position: relative;
  }

  .mugsbgstyle{
    background-image: url('./assets/mugsbgimg-1.svg');
    height:80vh; 
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 100% 100%; 
   position: relative;
  }
  .mugsbgstyle1{
    background-image: url('./assets/mugs-bg1.svg');
    height:100vh; 
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 100% 100%; 
   position: relative;
  }
  .mugslastsec{
    backdrop-filter: blur(30px);
  }
  .platersbgstyle{
    background-image: url('./assets/platersbg1.svg');
    height:80vh; 
   background-repeat: no-repeat;
   background-size: contain;
   background-position: 100% 100%; 
   position: relative;
  }
  .platesbgstyle1{
    background-image: url('./assets/platersbg2.svg');
    height:100vh; 
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 100% 100%; 
   position: relative;
  }

  .vasesbgstyle1{
   background: url('./assets/vasesbg2.svg') no-repeat center center; 
   background-size: cover;
   height: 100vh;
   position: relative;
  }
  .text-box {
    top: 74%;
    left: 72%;
    
    transform: translate(-50%, -50%);
    width: 55%;
  }
  
  @media (max-width: 768px) {
    .text-box {
      top: 40%;
      left: 50%;
      transform: translate(-50%, -40%);
      width: 100%;
    }
  
    .jost.fs-30 {
      font-size: 24px;
    }
  
    .jost.fs-19 {
      font-size: 16px;
    }
  }

  /* ======== */
  .vasesbgstyle {
    background: url('./assets/vasesbg1.svg') no-repeat center center; 
    background-size: contain;
    height: 80vh;
    position: relative;
  }
  
  .info-box {
    border-radius: 17px;
    background-color: white;
    position: absolute;
    top: 7%;
    left: 55%;
    right: 1%;
    bottom: 62%;
    box-shadow: 0px 0px 5px #E0E0E0;
  }
  
  @media (max-width: 768px) {
    .info-box {
      top: 5%;
      left: 5%;
      right: 5%;
      bottom: auto;
      transform: none;
      width: auto;
    }
  
    .jost.fs-30 {
      font-size: 24px;
    }
  
    .fw-400.fs-19 {
      font-size: 16px;
    }
  }

  
  @media (max-width: 768px) {
    .carditem {
      text-align: center;
    }
    .vasesbgstyle{
      height: 24vh;
    }
  }
 /* ==========textile page=========== */
   .category-card1 {
    background: url('./assets/textilebg1.svg') no-repeat center center; 
    background-size: cover;
    height: 55vh;
    position: relative;
    overflow: hidden;
  }
  .category-card {
    position: relative;
    overflow: hidden;
  }
  
  .category-card-text {
    position: absolute;
    width: 100%;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 500;
    font-family: "Jost", sans-serif;
    font-size: 22px;
    text-align: center;
    background-color: hsla(0, 0%, 0%, 0.2);
    padding: 10px;
    backdrop-filter: blur(3px);

  }
 
  .category-card-text1 {
    position: absolute;
    top: 20%;
    left: 10%;
    right: 55%;
  
  }

  /* ========= CeramicPage style =========== */

  .ceramicbgimg1 {
    background-image: url('./assets/ceramic-group-img.svg');
    height: 150vh;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: 100% 100%; */
    position: relative;
    background-position: center;
}

.text-block {
    position: absolute;
    left: 44%;
    right: 8%;
    bottom: 0%;
}

.block1 {
    top: 3%;
}

.block2 {
    top: 62%;
}

.heading1 {
    color: hsla(27, 100%, 30%, 1);
}

.heading2 {
    color: white;
}

.paragraph1 {
    color: hsla(0, 0%, 0%, 0.5);
}

.paragraph2 {
    color: hsla(0, 0%, 100%, 0.7);
}

/* Media queries for responsiveness */
@media (max-width: 992px) {
    .text-block {
        left: 5%;
        right: 5%;
        width: 90%;
    }
    
    .heading1, .heading2 {
        font-size: 30px;
    }
    
    .paragraph1, .paragraph2 {
        font-size: 14px;
    }

    .block1, .block2 {
        position: relative;
        top: 0;
    }
    
    .block2 {
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .ceramicbgimg1 {
        height: auto;
        background-size: cover;
    }
    
    .heading1, .heading2 {
        font-size: 25px;
    }
    
    .paragraph1, .paragraph2 {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .heading1, .heading2 {
        font-size: 20px;
    }
    
    .paragraph1, .paragraph2 {
        font-size: 10px;
    }
}

/* ========================== Product List Style =====================  */

.filter-section ul {
  list-style: none;
  padding: 0;
} 
/* ================Switch color style ============= */


.colors {
  display: flex;
  
  align-items: center;
}

.color-item {
  margin-right: 10px;
}

.color-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 31%, 1);
  display: inline-block;
}


/* src/TabToggle.css */
.tab1 {
  cursor: pointer;
}

.active-tab {
  color: black !important;
  font-weight: 600;
  
}

.tab-content {
  margin-top: 20px;
}
/* =============Addtocart============= */
.table-container {
  max-width: 600px;
  margin-top: 16%;
  border: 1px solid #DEDEDE;
  border-radius: 7px;
  overflow: hidden;
 
}
.custom-tbody td {
  padding: 15px !important;
}


/* =========== Signin-page =================  */

.signin-email{
  border: 1px solid #DCDCDC;
  width: 370px;
  height: 48px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Jost", sans-serif;
  padding-left: 15px;

outline: none;

}

.underline:after {
	content: '';
	display: block;
	width: 160px;
	height: 1px;
	background-color:#DCDCDC;
}